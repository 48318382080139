<template>
  <div>
    <div class="bg-image" style="background-image: url('https://source.unsplash.com/1920x1280/?forest')">
      <div class="bg-black-25">
        <div class="content content-full">
          <div class="py-5 text-center">
            <h1 class="font-w700 my-2 text-white">Edit Entity</h1>
            <h2 class="h4 font-w700 text-white-75">{{ entity.name }}</h2>
            <router-link class="btn btn-hero-primary" :to="{ name: 'entity-view', params: { id: entity._id } }">
              <i class="fa fa-fw fa-arrow-left mr-1"></i> Back to entity
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="content content-full content-boxed">
      <div v-show="entityError" class="alert alert-danger">{{ entityError }}</div>
      <div class="block block-rounded">
        <div class="block-content">
          <form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
            <!-- User Profile -->
            <h2 class="content-heading pt-0"><i class="fa fa-fw fa-briefcase text-muted mr-1"></i> Company Details</h2>
            <div class="row push">
              <div class="col-lg-4">
                <p class="text-muted">Information about the company/fund.</p>
              </div>
              <div class="col-lg-8 col-xl-5">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input id="name" v-model="form.name" type="text" class="form-control" name="name" placeholder="Enter your name.." />
                </div>
                <div class="form-group">
                  <label for="company-number">Name</label>
                  <input
                    id="company-number"
                    v-model="form.companyNumber"
                    type="text"
                    class="form-control"
                    name="company-number"
                    placeholder="Enter your name.."
                  />
                </div>
                <div class="form-group">
                  <label for="company-type">Company Type</label>
                  <input
                    id="company-type"
                    v-model="form.companyType"
                    type="text"
                    class="form-control"
                    name="company-type"
                    placeholder="Enter your name.."
                  />
                </div>
              </div>
            </div>
            <h2 class="content-heading pt-0"><i class="fa fa-fw fa-building text-muted mr-1"></i> Registered Address</h2>
            <div class="row push">
              <div class="col-lg-4">
                <p class="text-muted">Update the registered office of this company/fund.</p>
              </div>
              <div class="col-lg-8 col-xl-5">
                <AddressForm v-model="form.registeredAddress" />
              </div>
            </div>
            <div class="row push">
              <div class="col-lg-8 col-xl-5 offset-lg-4">
                <div class="form-group">
                  <button type="submit" class="btn btn-primary"><i class="fa fa-check-circle mr-1"></i> Update Profile</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import AddressForm from '@/components/forms/AddressForm';

export default {
  name: 'CompanyEdit',
  components: {
    AddressForm
  },
  data() {
    return {
      form: {
        registeredAddress: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      entity: 'entity/entity',
      entityError: 'entity/error'
    })
  },
  watch: {
    entity(entity) {
      this.form = {
        name: entity.name,
        companyNumber: entity.companyNumber,
        companyType: entity.companyType,
        registeredAddress: entity.registeredAddress
      };
    }
  },
  created() {
    this.getEntity({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getEntity: 'entity/get',
      updateEntity: 'entity/update'
    }),
    async onSubmit() {
      await this.updateEntity({ id: this.$route.params.id, data: this.form });
      this.$toasted.success('Entity updated successfully!', { position: 'bottom-right', duration: 3000 });
    }
  }
};
</script>
